import { Colors } from '../Variables.style';
import { rgba } from '../Mixins.style';

const theme = {
  loadingScreen: {
    primaryBackground: Colors.supernova,
    secondaryBackground: Colors.tanHide,
    color: Colors.white
  },
  button: {
    primaryBackground: Colors.supernova,
    secondaryBackground: Colors.tanHide,
    disabledBackground: Colors.wildSand,
    color: Colors.white
  },
  link: {
    color: Colors.black,
    colorOnHover: Colors.tanHide,
    underlineColor: Colors.tanHide
  },
  title: {
    color: Colors.tanHide
  },
  rule: {
    checkbox: {
      active: Colors.tanHide,
      inactive: Colors.doveGray
    }
  },
  titledeco: {
    underlineColor: Colors.tanHide
  },
  select: {
    container: {
      shadow: rgba(Colors.black, 0.16),
      background: Colors.wildSand
    },
    placeholder: {
      color: Colors.doveGray
    },
    label: {
      color: Colors.black
    },
    menu: {
      background: Colors.wildSand,
      thumb: Colors.supernova,
      track: Colors.wildSand,
      shadow: rgba(Colors.black, 0.16)
    },
    option: {
      color: Colors.doveGray,
      backgroundOnHover: Colors.whiteTwo,
      backgroundOnSelect: Colors.whiteTwo
    },
    noOption: {
      color: Colors.doveGray
    },
    indicator: {
      color: Colors.doveGray
    }
  },
  inputBox: {
    background: Colors.wildSand
  },
  input: {
    placeholderColor: Colors.delta,
    borderOnFocus: Colors.supernova,
    borderOnInvalid: Colors.red,
    borderOnValid: Colors.tanHide,
    shadow: rgba(Colors.black, 0.16)
  },
  inputIcon: {
    placeholderColor: Colors.delta,
    color: Colors.mineShaft
  },
  inputsign: {
    color: Colors.doveGray
  },
  error: {
    color: Colors.red,
    shadow: rgba(Colors.black, 0.16)
  },
  label: {
    color: Colors.black,
    shadow: rgba(Colors.black, 0.16)
  },
  notification: {
    color: Colors.black,
    shadow: rgba(Colors.black, 0.16)
  },
  tooltipIcon: {
    color: Colors.tanHide
  },
  tooltipText: {
    color: Colors.delta
  },
  forgotPassword: {
    link: {
      color: Colors.black,
      colorOnHover: Colors.tanHide,
      underlineColor: Colors.tanHide
    }
  },
  module: {
    default: {
      primaryBackground: Colors.supernova,
      secondaryBackground: Colors.tanHide,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    imageQC: {
      primaryBackground: Colors.astral,
      secondaryBackground: Colors.atoll,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    checker: {
      primaryBackground: Colors.cornflowerBlue,
      secondaryBackground: Colors.heliotrope,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    reports: {
      primaryBackground: Colors.salmon,
      secondaryBackground: Colors.wildWatermelon,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    viewer: {
      primaryBackground: Colors.java,
      secondaryBackground: Colors.surfieGreen,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    visits: {
      primaryBackground: Colors.mulberry,
      secondaryBackground: Colors.gigas,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    hyper: {
      primaryBackground: Colors.ceriseRed,
      secondaryBackground: Colors.rouge,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    smallViewer: {
      primaryBackground: Colors.supernova,
      secondaryBackground: Colors.tanHide,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    },
    references: {
      primaryBackground: Colors.alizarinCrimson,
      secondaryBackground: Colors.monarch,
      color: Colors.white,
      shadow: rgba(Colors.black, 0.1)
    }
  }
};

export default theme;
