import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Imagebox as commonImagebox,
  Image as commonImage
} from 'assets/Common/SignWrapper.style';

export const Imagebox = styled.div`
  ${commonImagebox}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Wrapper = styled.div`
  ${commonWrapper}
`;
