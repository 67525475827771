import { useState } from 'react';

import ForgotPasswordPanel from 'components/ForgotPasswordPanel';
import Helmet from 'components/Helmet';
import Logo from 'components/Logo';

import IconBackground from 'components/IconBackground/IconBackground';
import MessagePanel from 'components/MessagePanel';
import { requestPasswordResetEmail } from 'requestAPI';
import { Content, Section, Wrapper } from './ForgotPasswordPage.style';

const STATUSES = {
  IDLE: 'IDLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const ForgotPasswordPage = () => {
  const [status, setStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async values => {
    const res = await requestPasswordResetEmail(values.email);

    if (res.error) {
      setStatus(STATUSES.ERROR);
      return;
    }

    setStatus(STATUSES.SUCCESS);
  };

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.FORGOT_PASSWORD'} />
      <Section>
        <Wrapper>
          <Content>
            <Logo />
            {status === STATUSES.IDLE ? (
              <ForgotPasswordPanel onSubmit={handleSubmit} />
            ) : null}
            {status === STATUSES.SUCCESS ? (
              <MessagePanel
                title='E-mail has been sent'
                description='An email with instructions on how to reset your password has been sent to your inbox. If you do not receive it within a few minutes, please check that the provided email address matches the one used during account registration, and try again or contact us for assistance.'
              />
            ) : null}
            {status === STATUSES.ERROR ? (
              <MessagePanel
                title='Opss...'
                description='An error occurred while trying to send the email with password reset instructions.'
              />
            ) : null}
          </Content>
        </Wrapper>
        <IconBackground />
      </Section>
    </>
  );
};

export default ForgotPasswordPage;
