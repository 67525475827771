import useWindowSize from '@rehooks/window-size';

import Helmet from 'components/Helmet';
import Logo from 'components/Logo';
import Logout from 'components/Logout';
import SelectModulePanel from 'components/SelectModulePanel';

import { Breakpoints } from 'assets/Variables.style';
import {
  Content,
  Navigation,
  Section,
  Wrapper
} from './SelectModulePage.style';
import IconBackground from 'components/IconBackground/IconBackground';

const SelectModulePage = () => {
  let windowSize = useWindowSize();

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.SELECT_MODULE'} />
      <Section>
        {windowSize.innerWidth > Breakpoints.md && (
          <Navigation>
            <Logout />
          </Navigation>
        )}
        <Wrapper>
          <Content>
            <Logo />
            <SelectModulePanel />
            {windowSize.innerWidth <= Breakpoints.md && (
              <Navigation>
                <Logout />
              </Navigation>
            )}
          </Content>
        </Wrapper>
        <IconBackground />
      </Section>
    </>
  );
};

export default SelectModulePage;
