import React, { createContext, useState } from 'react';
import { createTheming } from '@callstack/react-theme-provider';
import config from 'config';
import defaultTheme from 'assets/Themes/DefaultTheme';
import SIFTheme from 'assets/Themes/SIFTheme';

const { ThemeProvider, useTheme } = createTheming(defaultTheme);

const Context = createContext();

const Themes = [
  { name: 'classic', styles: defaultTheme },
  { name: 'light', styles: defaultTheme },
  { name: 'dark', styles: defaultTheme },
  { name: 'fuchsia', styles: defaultTheme },
  { name: 'sif', styles: SIFTheme }
];

const ThemeProviderWrapper = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    return config.theme;
  });

  const currentTheme =
    Themes.find(theme => theme.name === theme) ||
    Themes.find(theme => theme.name === config.theme);

  return (
    <Context.Provider value={{ theme, switchTheme: setTheme }}>
      <ThemeProvider theme={currentTheme.styles}>{children}</ThemeProvider>
    </Context.Provider>
  );
};

export { ThemeProviderWrapper, useTheme, Context as ThemeContext };
