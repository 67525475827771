import ChangePasswordPanel from 'components/ChangePasswordPanel';
import Helmet from 'components/Helmet';
import Logo from 'components/Logo';
import MessagePanel from 'components/MessagePanel';
import { useState } from 'react';
import { requestPasswordChange } from 'requestAPI';
import useQuery from 'utils/useQuery';
import { Content, Section, Wrapper } from './ChangePasswordPage.style';

import IconBackground from 'components/IconBackground/IconBackground';

const ChangePasswordPage = () => {
  let query = useQuery();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const tokenCode = query.get('code');

  const handleSubmit = async values => {
    const res = await requestPasswordChange({
      token: tokenCode,
      password: values.password
    });

    if (res.error) {
      setError(res.message);
      return;
    }

    setSuccess(true);
  };

  return (
    <>
      <Helmet title='LANG_PAGE_TITLE.CHANGE_PASSWORD' />
      <Section>
        <Wrapper>
          <Content>
            <Logo />
            {!success && !error && tokenCode && tokenCode !== '' ? (
              <ChangePasswordPanel onSubmit={handleSubmit} />
            ) : null}
            {!error && (tokenCode == null || tokenCode === '') ? (
              <MessagePanel
                title='Token not provided'
                description='Please enter correct token.'
              />
            ) : null}
            {error !== '' ? (
              <MessagePanel
                title='Password change failed'
                description={error}
              />
            ) : null}
            {success ? (
              <MessagePanel
                title='Success'
                description='Your password has been successfully changed.'
              />
            ) : null}
          </Content>
        </Wrapper>
        <IconBackground />
      </Section>
    </>
  );
};

export default ChangePasswordPage;
