import { useContext } from 'react';
import { Wrapper, Imagebox, Image } from './IconBackground.style';
import IMG_BMS_ICON from 'assets/Images/SIFD_Orange_icon_BMS.svg';
import IMG_BMS_ICON_SIF from 'assets/Images/SIFD_Orange_icon_BMS_SIF.svg';
import { ThemeContext } from 'ThemeContext';

const LOGOS = {
  sif: IMG_BMS_ICON_SIF,
  classic: IMG_BMS_ICON
};

const IconBackground = () => {
  const theme = useContext(ThemeContext);
  console.log('theme', theme);

  return (
    <Wrapper hideMedium>
      <Imagebox>
        <Image src={LOGOS[theme.theme]} alt='BMS' />
      </Imagebox>
    </Wrapper>
  );
};

export default IconBackground;
