export const config = {
  appName: 'BMS',
  lang: 'en-us',
  theme: 'sif',
  connect: {
    host: {
      bms: process.env.REACT_APP_BMS_URI,
      sso: process.env.REACT_APP_SSO_URI,
      master: process.env.REACT_APP_MASTER_URI
    },
    direction: {
      bms: '/api',
      sso: '/api',
      master: ''
    }
  },
  auth: {
    tokenCheckInterval: 1,
    login: '/sign-in',
    homepage: '/select-module',
    logout: '/sign-out'
  },
  modules: [
    {
      name: 'Image QC',
      value: '/image-qc/',
      privileges: [1, 400],
      theme: 'imageQC'
    },
    {
      name: 'Checker',
      value: '/checker/',
      privileges: [1, 402],
      theme: 'checker'
    },
    {
      name: 'Reports',
      value: '/reports/',
      privileges: [1, 403],
      theme: 'reports'
    },
    {
      name: 'Viewer',
      value: '/viewer/',
      privileges: [1, 401],
      theme: 'viewer'
    },
    {
      name: 'Small viewer',
      value: '/small-viewer/',
      privileges: [1, 404],
      theme: 'smallViewer'
    },
    {
      name: 'Visits',
      value: '/visits/',
      privileges: [1, 405],
      theme: 'visits'
    },
    {
      name: 'Hyper',
      value: `/hyper/`,
      privileges: [1, 406],
      theme: 'hyper',
      isExternal: false
    },
    {
      name: 'References',
      value: `/references/`,
      privileges: [1, 407],
      theme: 'references'
    }
  ]
};
export default config;
