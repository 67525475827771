import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import IMG_BMS from 'assets/Images/SIFD_Orange_BMS_SIF.svg';
import SIF_LOGO from 'assets/Images/SIF_LOGO.png';
import { Link, Image } from './Logo.style';
import { ThemeContext } from 'ThemeContext';

const LOGOS = {
  sif: SIF_LOGO,
  classic: IMG_BMS
};

const Logo = () => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  return (
    <Link
      to='/'
      title={intl.formatMessage({
        id: `LOGO.GO_TO_HOME`
      })}
    >
      <Image src={LOGOS[theme.theme]} alt='BMS' />
    </Link>
  );
};

export default Logo;
